<script>
import { ArrowUpIcon, CheckIcon, MapPinIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Page-jobs component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    CheckIcon,
    MapPinIcon,
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Jobs / Careers</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item active" aria-current="page">
                      Jobs
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Job List Start -->
    <section class="section">
      <div class="container">
        <div class="row ">
          <div class="col-md-10 ">
            <h2 class="text-primary ">Join Our Team!</h2>
            <h6 class="">
              We are always looking for motivated individuals to grow our team.
              If you are a great team player who is passionate about his/her
              work, send us your resume. We’d love the opportunity to talk with
              you about our current openings.
            </h6>
          </div>
        </div>
        <div class="row mt-100">
          <div class="col-12">
            <div class="border-bottom">
              <div class="row">
                <div class="col-lg-9 col-md-8">
                  <div class="section-title">
                    <h4 class="title mb-2">All Jobs</h4>
                    <p class="text-muted mb-0">Most Relevance Job</p>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="form custom-form">
                    <div class="form-group">
                      <label>Types of jobs :</label>
                      <select
                        class="form-control custom-select"
                        id="Sortbylist-job"
                      >
                        <option>All Jobs</option>
                        <option>Full Time</option>
                        <option>Part Time</option>
                        <option>Remote</option>
                        <option>Work From Home</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-12 mt-4 pt-2">
            <div class="section-title">
              <h5 class="mb-0">Current Opennings:</h5>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card job-box rounded shadow border-0 overflow-hidden">
              <div class="border-bottom">
                <div class="position-relative">
                  <img src="images/job/full.jpg" class="img-fluid" alt="" />
                  <div class="job-overlay bg-white"></div>
                </div>
                <h5 class="mb-0 position">
                  <router-link to="/page-job-detail" class="text-dark"
                    >Business Developer</router-link
                  >
                  <ul class="list-unstyled h6 mb-0 text-warning">
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                  </ul>
                </h5>
                <ul class="list-unstyled head mb-0">
                  <li class="badge badge-success badge-pill">Full Time</li>
                </ul>
              </div>

              <div class="card-body content position-relative">
                <div
                  class="firm-logo rounded-circle shadow bg-light text-center"
                >
                  <img
                    src="images/favicon.ico"
                    class="avatar avatar-md-sm"
                    alt=""
                  />
                </div>
                <div class="company-detail text-center mt-3">
                  <h5 class="mb-0">
                    <router-link
                      to="/page-job-company"
                      class="text-dark company-name"
                    ></router-link>
                  </h5>
                  <p class="text-muted">
                    <a href="" class="video-play-icon text-muted">
                      <map-pin-icon class="fea icon-sm"></map-pin-icon>
                      Washington, DC
                    </a>
                  </p>
                </div>
                <ul class="job-facts list-unstyled">
                  <li class="list-inline-item text-muted">
                    <check-icon
                      class="fea icon-sm text-success mr-1"
                    ></check-icon>
                    Business Development Experience
                  </li>

                  <li class="list-inline-item text-muted">
                    <check-icon
                      class="fea icon-sm text-success mr-1"
                    ></check-icon>
                    Driven and self starter
                  </li>
                </ul>
                <router-link
                  to="/contact"
                  class="btn btn-outline-primary btn-block"
                  >Apply Now</router-link
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card job-box rounded shadow border-0 overflow-hidden">
              <div class="border-bottom">
                <div class="position-relative">
                  <img src="images/job/remote.jpg" class="img-fluid" alt="" />
                  <div class="job-overlay bg-white"></div>
                </div>
                <h5 class="mb-0 position">
                  <router-link to="/page-job-detail" class="text-dark"
                    >Inside Sales</router-link
                  >
                  <ul class="list-unstyled h6 mb-0 text-warning">
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="list-inline-item mb-0">
                      <i class="mdi mdi-star"></i>
                    </li>
                  </ul>
                </h5>
                <ul class="list-unstyled head mb-0">
                  <li class="badge badge-success badge-pill">Full Time</li>
                </ul>
              </div>

              <div class="card-body content position-relative">
                <div
                  class="firm-logo rounded-circle shadow bg-light text-center"
                >
                  <img
                    src="images/favicon.ico"
                    class="avatar avatar-md-sm"
                    alt=""
                  />
                </div>
                <div class="company-detail text-center mt-3">
                  <h5 class="mb-0">
                    <router-link
                      to="/page-job-company"
                      class="text-dark company-name"
                    ></router-link>
                  </h5>
                  <p class="text-muted">
                    <a href="" class="video-play-icon text-muted">
                      <map-pin-icon class="fea icon-sm"></map-pin-icon>
                      Washington, DC
                    </a>
                  </p>
                </div>
                <ul class="job-facts list-unstyled">
                  <li class="list-inline-item text-muted">
                    <check-icon
                      class="fea icon-sm text-success mr-1"
                    ></check-icon>
                    Excellent Communication skills
                  </li>

                  <li class="list-inline-item text-muted">
                    <check-icon
                      class="fea icon-sm text-success mr-1"
                    ></check-icon>
                    Driven and self starter
                  </li>
                </ul>
                <router-link
                  to="/contact"
                  class="btn btn-outline-primary btn-block"
                  >Apply Now</router-link
                >
              </div>
            </div>
          </div>
          <!--end col-->
          <!-- PAGINATION END -->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Job List End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
